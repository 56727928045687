import React from "react"
import logo from "../../images/school-live-logo.png"
import { Link } from "gatsby"
import "./style.scss"
import NavbarMobile from "../navbarmobile"
export default function Navbar() {
  return (
    <div className="nav">
      <div className="nav--desktop">
        <Link to="/">
          <img className="nav__logo" alt="School Live Logo" src={logo} />
        </Link>
        <div className="nav__links">
          <Link className="nav__links__link" to="/membership">
            Membership
          </Link>
          <a className="nav__links__link" href="/#advertising">
            Advertising
          </a>
          <Link className="nav__links__link" to="/connect">
            Connect
          </Link>
          {/* <Link className="nav__links__link" to="/#read">
            READ
          </Link> */}
        </div>
      </div>
      <div className="nav--mobile">
        <Link to="/">
          <img className="nav__logo" alt="School Live Logo" src={logo} />
        </Link>
        <NavbarMobile />
      </div>
    </div>
  )
}
