import React, { Component } from "react"
import "./style.scss"
class NavbarMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      navShow: false,
    }

    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => {
      return { navShow: !state.navShow }
    })
  }

  render() {
    return (
      <div className="navbarmobile">
        <div
          className={`navbarmobile__background ${
            this.state.navShow && "navbarmobile__background--show"
          }`}
        ></div>

        <button
          className={`hamburger hamburger--elastic ${
            this.state.navShow && "is-active"
          } navbarmobile__toggler`}
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
          onClick={this.handleToggleClick}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner makewhite"></span>
          </span>
        </button>

        <div
          className={`navbarmobile__links ${
            this.state.navShow && "navbarmobile__links--show"
          }`}
        >
          <ol>
            <li
              onClick={this.handleToggleClick}
              onKeyDown={this.handleToggleClick}
            >
              <a href="/subscriptions">Subscriptions</a>
            </li>{" "}
            <li
              onClick={this.handleToggleClick}
              onKeyDown={this.handleToggleClick}
            >
              <a href="/">Membership</a>
            </li>
            <li
              onClick={this.handleToggleClick}
              onKeyDown={this.handleToggleClick}
            >
              <a href="#advertising">Advertising</a>
            </li>
            <li
              onClick={this.handleToggleClick}
              onKeyDown={this.handleToggleClick}
            >
              <a href="/connect">Connect</a>
            </li>
            {/* <li onClick={this.handleToggleClick}>
              <a href="#contact">R.E.A.D</a>
            </li> */}
          </ol>
        </div>
      </div>
    )
  }
}

export default NavbarMobile
